"use strict";

/*globals Kobo,Modernizr,ko,DynamicConfiguration*/
Kobo.Utilities = function () {
  "use strict";

  return {
    navigateTo: function navigateTo(url) {
      window.location.href = url;
    },
    goBack: function goBack() {
      window.history.back();
    },
    reload: function reload() {
      window.location.reload(true);
    },
    getLocationHash: function getLocationHash() {
      return window.location.hash;
    },
    replaceState: function replaceState(url) {
      if (window.history.replaceState) {
        window.history.replaceState(null, null, url);
      }
    },
    repeatString: function repeatString(string, repeatTimes) {
      var repeated = [];

      if (repeatTimes && repeatTimes > 1) {
        while (repeated.length < repeatTimes) {
          repeated.push(string);
        }

        return repeated.join('');
      }

      return string;
    },
    stringFormat: function stringFormat() {
      var args = arguments,
          string = args[0];
      return string.replace(/\{(\d+)\}/g, function (match, number) {
        var index = parseInt(number, 10) + 1,
            replacement = args[index];
        return replacement || match;
      });
    },
    getProductActionClassList: function getProductActionClassList() {
      return ['checkout', 'add-to-cart', 'pre-order-checkout', 'external-redirect', 'add-to-library', 'add-preview-to-library', 'redeem-with-points', 'add-to-reading-list-under-subscription', 'add-preview-to-reading-list'];
    },
    escapeStringForRegex: function escapeStringForRegex(string) {
      return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    getQueryStringParameterByName: function getQueryStringParameterByName(url, name) {
      var match = new RegExp('[?&]' + Kobo.Utilities.escapeStringForRegex(name) + '=([^&]*)').exec(url);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    },
    updateQueryStringParameter: function updateQueryStringParameter(url, key, value) {
      var re = new RegExp("([?&])" + Kobo.Utilities.escapeStringForRegex(key) + "=.*?(&|$)", "i"),
          separator = url.indexOf('?') !== -1 ? "&" : "?";

      if (url.match(re)) {
        return url.replace(re, '$1' + key + "=" + value + '$2');
      }

      return url + separator + key + "=" + value;
    },
    removeQueryStringParameter: function removeQueryStringParameter(url, parameter) {
      var i;
      var urlparts = url.split('?');

      if (urlparts.length >= 2) {
        var prefix = parameter + '=';
        var parameters = urlparts[1].split(/[&;]/g); //reverse iteration as may be destructive

        for (i = parameters.length - 1; i >= 0; i--) {
          //idiom for string.startsWith
          if (parameters[i].lastIndexOf(prefix, 0) !== -1) {
            parameters.splice(i, 1);
          }
        }

        url = urlparts[0] + (parameters.length ? '?' + parameters.join('&') : '');
        return url;
      }

      return url;
    },
    productActionEventMapper: function productActionEventMapper(productActionClassName) {
      switch (productActionClassName) {
        case 'checkout':
        case 'pre-order-checkout':
          return 'purchaseBroker::initiateCheckout';

        case 'add-to-cart':
          return 'shoppingCartItems::addItem';

        case 'add-to-reading-list-under-subscription':
          return 'productAction::addToReadingListUnderSubscription';

        case 'add-to-library':
          return 'library::add';

        case 'add-preview-to-library':
        case 'add-preview-to-reading-list':
          return 'library::addPreview';

        case 'redeem-with-points':
          return 'koboLove::redeemStart';
      }

      return null;
    },

    /**
     * Description: Overriding Modernizr's touch detection to give a non-touch UX
     * TODO Delete isTouch when old carousels are removed from the solution
     */
    isTouch: function isTouch() {
      if (window.DynamicConfiguration && window.DynamicConfiguration.ForceDisableTouch) {
        return false;
      }

      return Modernizr && Modernizr.touchevents;
    },
    storage: function () {
      if (Modernizr.localstorage) {
        return window.localStorage;
      }

      return Kobo.LocalMemoryStorage;
    }(),
    renderOnServer: function renderOnServer($destination, url, data, httpMethod, ajaxFailCallback) {
      httpMethod = httpMethod || 'GET';
      $destination.empty();
      Kobo.Spinner.showSpinner($destination);
      return Kobo.$.ajax({
        method: httpMethod,
        url: url,
        data: data,
        dataType: 'html',
        cache: false,
        success: function success(response) {
          var $renderedContent = Kobo.$(response);
          $destination.html($renderedContent);
        },
        error: function error() {
          if (ajaxFailCallback) {
            ajaxFailCallback();
          }
        },
        complete: function complete() {
          Kobo.Spinner.hideSpinner($destination);
        }
      });
    },
    showMessageDialog: function showMessageDialog(options) {
      var settings = Kobo.$.extend({
        title: null,
        message: null,
        okLabel: null,
        cancelLabel: null,
        okCallback: null,
        cancelCallback: null
      }, options);
      var buttons = [];

      if (settings.cancelLabel) {
        buttons.push({
          label: settings.cancelLabel,
          action: settings.cancelCallback
        });
      }

      if (settings.okLabel) {
        buttons.push({
          label: settings.okLabel,
          action: settings.okCallback
        });
      }

      Kobo.Utilities.showDialog({
        title: settings.title,
        content: settings.message,
        buttons: buttons
      });
    },
    showDialog: function showDialog(options) {
      var $view = Kobo.$(Kobo.$('#dialog-template').html());
      var buttons = Kobo.$.map(options.buttons, function (button) {
        return new Kobo.Controls.Dialog.ButtonVM(button);
      });
      var viewModel = new Kobo.Controls.Dialog.DialogVM({
        title: options.title,
        content: options.content,
        buttons: buttons
      });
      viewModel.notifyClosingEvent.subscribe(function () {
        Kobo._modal.close();
      });
      ko.applyBindings(viewModel, $view[0]);

      Kobo._modal.open($view);
    },

    /* TODO - This should get bumped out into a separate file and made more generic/reusable */

    /* used for deal notification and kobolove notification logo must be added via CSS and class passed in as arg.*/
    buildAddToCartNotification: function () {
      var $notificationText,
          $notificationWrapper,
          buildNotification = function buildNotification(type) {
        var $closeButton = Kobo.$("<button type=\"button\" aria-label=\"".concat(DynamicConfiguration.resourceStrings.close, "\" class=\"close\"/>")),
            $notificationTextWrapper = Kobo.$('<div class="notification-text-wrapper"></div>');
        $notificationText = Kobo.$('<div class="notification-text-area" role="alert" aria-live="polite"></div>');

        if (type === "love") {
          $notificationWrapper = Kobo.$('<div class="add-to-cart-notification love"><div class="logo-membership"/></div>');
        } else {
          $notificationWrapper = Kobo.$('<div class="add-to-cart-notification"><div class="logo-circle"/></div>');
        }

        $notificationTextWrapper.append($notificationText);
        $notificationTextWrapper.append($closeButton);
        $notificationWrapper.append($notificationTextWrapper);
        $notificationWrapper.on('focusClose', function () {
          $closeButton.focus();
        });
        $closeButton.on('click', function () {
          $notificationWrapper.removeClass('show');
        });
        return Kobo.$body.prepend($notificationWrapper);
      };

      return {
        open: function open(type, content) {
          if (!$notificationWrapper) {
            buildNotification(type);
          }

          $notificationText.html(content);
          $notificationWrapper.addClass('show');
          $notificationWrapper.trigger('focusClose');
        },
        close: function close() {
          $notificationWrapper.removeClass('show');
        }
      };
    }(),
    assignToNamespace: function assignToNamespace(nameSpace, item) {
      var split = nameSpace.split('.');
      var parent = window;
      var current = '';
      var length = split.length;
      var i;

      for (i = 0; i < length; i++) {
        current = split[i];

        if (current.length > 0) {
          if (i < length - 1) {
            parent[current] = parent[current] || {};
          } else {
            parent[current] = parent[current] || item;
          }

          parent = parent[current];
        } else {
          throw 'The namespace cannot contain empty properties';
        }
      }

      return parent;
    },
    iePlaceholder: function iePlaceholder(inputSelector, item, isPasswordType) {
      if (!Modernizr.input.placeholder) {
        // ie9 shows the placeholder as a password
        if (isPasswordType === true && Kobo.$('html').hasClass('ie9')) {
          return;
        }

        var arr = Kobo.$.find(inputSelector + '[placeholder]'),
            i = 0,
            focus = function focus() {
          if (item.value === item.getAttribute('placeholder')) {
            item.value = '';
          }
        },
            blur = function blur() {
          if (item.value === '' || item.value === item.getAttribute('placeholder')) {
            item.value = item.getAttribute('placeholder');
          }
        },
            element = null;

        for (i; i < arr.length; i++) {
          element = arr[i];
          Kobo.$(element).focus(focus);
          Kobo.$(element).blur(blur);
          element.value = element.getAttribute('placeholder');
        }
      }
    },
    getRange: function getRange(low, high) {
      var range = [],
          value;

      for (value = low; value <= high; value++) {
        range.push(value);
      }

      return range;
    },
    fullPagePost: function fullPagePost(path, params, method) {
      var $form = Kobo.$('<form />'),
          key,
          $hiddenField;
      method = method || "post"; // Set method to post by default if not specified.

      $form.attr("method", method);
      $form.attr("action", path);

      for (key in params) {
        if (params.hasOwnProperty(key)) {
          $hiddenField = Kobo.$("<input />");
          $hiddenField.attr("type", "hidden");
          $hiddenField.attr("name", key);
          $hiddenField.attr("value", params[key]);
          $form.append($hiddenField);
        }
      }

      Kobo.$('body').append($form);
      $form[0].submit();
    },
    // WEB-21644: This should be broken down into two or more functions
    smoothAnchorScroll: function smoothAnchorScroll(anchorselector, offset) {
      Kobo.$(anchorselector).on('click', function () {
        if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
          var target = Kobo.$(this.hash);
          target = target.length ? target : Kobo.$('[name=' + this.hash.slice(1) + ']');

          if (target.length) {
            Kobo.$('html,body').animate({
              scrollTop: target.offset().top - (typeof offset === 'function' ? offset() : offset)
            }, 1000);
            return false;
          }
        }
      });
    },
    // Once WEB-21644 is fixed above, this could just call smoothAnchorScroll
    slideToTop: function slideToTop(config) {
      var $el = Kobo.$(config.el);
      var delay = typeof config.delay === 'number' ? config.delay : 500;
      var duration = typeof config.duration === 'number' ? config.duration : 400;
      var offset = typeof config.offset === 'number' ? config.offset : $el.offset().top;
      setTimeout(function () {
        Kobo.$('html, body').animate({
          scrollTop: offset
        }, duration);
      }, delay);
    },
    registerResourceStrings: function registerResourceStrings(resourceStrings) {
      window.DynamicConfiguration = window.DynamicConfiguration || {};
      window.DynamicConfiguration.resourceStrings = Kobo.$.extend(true, {}, window.DynamicConfiguration.resourceStrings, resourceStrings);
    },
    findAllFocusableElements: function findAllFocusableElements(parentElement) {
      return Kobo.$(parentElement).find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    },
    getAntiForgeryToken: function getAntiForgeryToken() {
      return $('#site-anti-forgery input[name="__RequestVerificationToken"]').val();
    },
    waitForElement: function waitForElement(selector, callback) {
      wait();

      function wait() {
        if ($.find(selector).length) {
          callback();
        } else {
          setTimeout(function () {
            wait(selector, callback);
          }, 100);
        }
      }
    },
    waitForElementToBeVisible: function waitForElementToBeVisible(selector, callback, timeout) {
      wait();

      function wait() {
        var waitTimeout = timeout || 100;
        var element = $.find(selector);

        if ($(element).length && $(element).is(':visible')) {
          callback();
        } else {
          setTimeout(function () {
            wait();
          }, waitTimeout);
        }
      }
    },
    fetchFirstNameAndLastName: function fetchFirstNameAndLastName(fullName) {
      var firstName = fullName.split(' ').slice(0, -1).join(' ');
      var lastName = fullName.split(' ').slice(-1).join(' ');
      return {
        firstName: firstName,
        lastName: lastName
      };
    }
  };
}();